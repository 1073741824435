import detectBrowserLanguage from 'detect-browser-language';
import cookie from './lib/cookie';
const frLang = ['fr', 'fr-BE', 'fr-CA', 'fr-FR',];
if (
    "" === cookie.getCookie('language_choice')
    && -1 === frLang.indexOf(detectBrowserLanguage())
    && '/fr/' === location.pathname
) {
  document.location.href="/en/";
}
const switcherItems = document.querySelectorAll('[data-lang-switcher]');
switcherItems.forEach((element) => {
  element.addEventListener('click', (e) => {
    e.preventDefault();
    cookie.setCookie('language_choice', element.getAttribute('data-lang-switcher'), 90);
    document.location.href = element.getAttribute('href');
  });
});
